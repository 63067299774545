<template>
  <div class="main-wrapper">
    <!-- Banner -->
    <gallery-slider
      v-if="this.company.images && this.company.images.length > 0"
      :data="this.company.images"
    ></gallery-slider>

    <banner-version-one
      v-else
      :title="''"
      :subtitle="''"
      :items="bannerImages"
    ></banner-version-one>

    <!-- Content -->
    <div class="container">
      <div class="content">
        <div class="row">
          <div class="col-12 mb-3">
            <v-breadcrumbs
              :items="defaultLinks"
              style="padding: 0px; margin-bottom: 30px; color: black"
              divider=">"
            ></v-breadcrumbs>
            <div class="row">
              <div class="col-lg-3 col-md-2 col-sm-12" v-if="company.logo">
                <div class="row" style="height: 100%">
                  <img
                    class="mx-auto"
                    :src="company.logo.route"
                    style="
                      max-width: 100%;
                      max-height: 200px;
                      width: auto;
                      height: fit-content;
                      align-self: center;
                      justify-self: center;
                    "
                    :alt="company.name"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="detail-tile mb-4">
                  <h3>
                    {{ company.name }}
                    <!--<span class="badge badge-pill badge-success text-uppercase">Open</span>-->
                  </h3>
                  <p>{{ company.description }}</p>
                  <div v-if="company.categories && company.categories.length > 0">
                    <p>
                      <strong>Categorías:</strong>
                      <span
                        style="margin-left: 5px"
                        v-for="category in company.categories"
                        :key="category.id"
                        class="badge badge-pill badge-primary text-uppercase badge-cat"
                        >{{ category.name }}</span
                      >
                    </p>
                  </div>
                  <div v-if="company.countries && company.countries.length > 0">
                    <p>
                      <strong>Presencia en:</strong>
                      <img
                        v-for="country in company.countries"
                        :key="country.name"
                        style="margin: 0 5px; width: 30px; height: 20px"
                        :src="country.flag"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="subs-widget bg-gradient-info p-4 text-center mb-4">
                  <h4 class="text-white mb-3">¿Te interesa?</h4>
                  <p class="text-white mb-2">
                    Solicita información acerca de esta empresa
                  </p>
                  <v-dialog
                    transition="dialog-top-transition"
                    v-model="dialog"
                    max-width="600"
                  >
                    <contact-form
                      :company="company"
                      style="margin: 0px auto"
                    ></contact-form>
                  </v-dialog>
                  <a
                    class="btn btn-primary"
                    style="color: #fff"
                    @click.prevent="$modal.show('contactForm')"
                    type="button"
                    >Contactar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-md-8 col-sm-12">
            <div class="row" v-if="dataAssigned.length != 0">
              <div class="col-lg-4">
                <ul
                  class="nav nav-pills nav-fill flex-column flex-md-row"
                  id="tabs-icons-text"
                  role="tablist"
                >
                  <li
                    class="row nav-item"
                    style="width: 90%; margin: 5px 0px"
                    v-for="tag in dataAssigned"
                    :key="tag.id"
                  >
                    <a
                      style="width: 100%"
                      class="nav-link mb-sm-3 mb-md-0"
                      :class="{
                        active: tag.id == firstTag ? true : false,
                        show: tag.id == firstTag ? true : false,
                      }"
                      @click="selected = tag.id"
                      :id="`tabs-icons-text-${tag.id}-tab`"
                      data-toggle="tab"
                      :href="`#tabs-icons-text-${tag.id}`"
                      role="tab"
                      :aria-controls="`tabs-icons-text-${tag.id}`"
                      :aria-selected="tag.id == firstTag ? true : false"
                      ><v-icon
                        dark
                        :color="selected == tag.id ? '#fff' : '#003a56'"
                        style="float: left"
                      >
                        mdi-checkbox-marked-circle </v-icon
                      >{{ tag.title }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-lg-8">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                      <div
                        v-for="tag in dataAssigned"
                        :key="tag.id"
                        class="tab-pane fade"
                        :class="{
                          active: tag.id == firstTag ? true : false,
                          show: tag.id == firstTag ? true : false,
                        }"
                        :id="`tabs-icons-text-${tag.id}`"
                        role="tabpanel"
                        :aria-labelledby="`tabs-icons-text-${tag.id}-tab`"
                      >
                        <div class="row">
                          <div
                            :class="
                              item.companyData[0].value.length < 200
                                ? 'col-lg-6 col-md-12 col-12'
                                : 'col-12'
                            "
                            v-for="item in tag.data"
                            :key="item.id"
                          >
                            <h6 class="" style="margin: 0px">{{ item.description }}:</h6>
                            <div
                              v-html="setDataText(item.id)"
                              style="text-align: justify"
                              class="text-box"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row" v-if="loading">
                <v-progress-circular
                  style="margin: auto"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div class="row">
                <h4 style="margin: auto">
                  {{ message }}
                </h4>
              </div>
            </div>
            <div
              class="row"
              style="margin: 50px auto"
              v-if="company.cof && company.outstanding === 1"
            >
              <h3 class="margin:auto">COF - Circular Oferta de Franquicia</h3>
              <iframe
                class=""
                :src="
                  'https://docs.google.com/gview?url=' +
                  company.cof.route +
                  '&embedded=true'
                "
                style="width: 80%; height: 600px"
                frameborder="0"
              >
              </iframe>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-12">
            <sidebar-layout-two
              class="mb-4"
              v-if="company.showcontact === 1"
              :title="'Contacto clave'"
              :name="company.user ? company.user.name + ' ' + company.user.lastname : ''"
              :email="company.email"
              :address="company.address"
              :phone1="company.phone"
              :phone2="company.phone2"
              :website="company.website"
              :avatar="company.user ? company.user.avatar : null"
              @showContactForm="$modal.show('contactForm')"
            ></sidebar-layout-two>

            <!-- <div class="" style="padding: 0rem 1.25rem 0.625rem 1.25rem">
              <div class="subs-widget bg-gradient-info p-4 text-center">
                <h4 class="text-white mb-3">¿Buscas un empleo?</h4>
                <p class="text-white mb-2">
                  Regístrate para solicitar un empleo en esta Franquicia
                </p>
                <v-dialog
                  transition="dialog-top-transition"
                  v-model="dialog"
                  max-width="600"
                  style=""
                >
                  <contact-form
                    :company="company"
                    style="margin: 0px auto"
                  ></contact-form>
                </v-dialog>
                <a
                  class="btn btn-primary"
                  style="color: #fff"
                  @click.prevent="$modal.show('contactForm')"
                  type="button"
                  >Postulate</a
                >
              </div>
            </div> -->

            <ads-section-3 :ads="v2Ads" style="padding: 0px"></ads-section-3>
          </div>
        </div>

        <slick
          v-if="company.infographics && company.infographics.length >= 1"
          :options="slickOptions"
          class="listing-slider mfp-gallery-container infographic"
          style="height: 200px; margin: 40px auto;"
          :key="slickIndex"
        >
          <div v-for="(image, index) in company.infographics" :key="index">
            <v-img
              :src="image.route"
              :alt="`image`"
              :lazy-src="image.route"
              :apect-ratio="800 / 600"
              contain="true"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-1" align="center" justify="center">
                  <v-progress-circular
                    class="mx-auto"
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </slick>
        <v-row>
          <ads-section-2 class="my-0" :ads="h2Ads"></ads-section-2>
        </v-row>

        <companies-carousel-2
          v-if="company.name"
          :country="country"
          :company="company"
        ></companies-carousel-2>

        <Whatsapp />
      </div>
    </div>
    <modal name="contactForm" :adaptive="true">
      <v-btn
        class="mr-1 mt-1"
        @click.prevent="$modal.hide('contactForm')"
        style="float: right"
        fab
        dark
        x-small
        color="primary"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <div class="p-4">
        <contact-form
          @sended="$modal.hide('contactForm')"
          :company="company"
          :title="contactFormTitle"
          style="margin: 0px auto"
        ></contact-form>
      </div>
    </modal>
  </div>
</template>

<script>
  import GallerySlider from 'Components/globalFrontendComponents/GallerySlider'
  import AdsSection3 from 'Components/globalFrontendComponents/AdsSection3'
  import ContactForm from 'Components/globalFrontendComponents/ContactForm'
  import AdsSection2 from 'Components/globalFrontendComponents/AdsSection2'
  import SidebarLayoutTwo from 'Views/listing/SidebarLayoutTwo'
  import Header2 from 'Components/globalFrontendComponents/Header2'
  import CompaniesCarousel2 from 'Components/globalFrontendComponents/CompaniesCarousel2.vue'
  import Whatsapp from '@/components/globalFrontendComponents/WhatsApp'
  import Slick from 'vue-slick'
  export default {
    name: 'CompanyDetail',
    props: ['country'],
    data() {
      return {
        slickIndex: 1,
        date: new Date().toISOString(),
        intervalFunction: '',
        bannerImages: [
          {
            id: 1,
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/48be7308d059818e3a53a7a7fd9b5102.jpg',
            bannerMobile:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/477eca69c2eb09e4459d7b5a7df17217.jpg',
          },
        ],
        headerGallerySlider: [
          {
            image: '/static/images/dp-1.jpg',
          },
          {
            image: '/static/images/dp-5.jpg',
          },
          {
            image: '/static/images/dp-3.jpg',
          },
          {
            image: '/static/images/dp-4.jpg',
          },
        ],
        center: {
          lat: -34.397,
          lng: 150.644,
        },
        dialog: false,
        loading: true,
        message: 'Cargando',
        firstTag: '',
        name: '',
        h1Ads: [],
        h2Ads: [],
        v1Ads: [],
        v2Ads: [],
        defaultLinks: [
          {
            text: 'Inicio',
            disabled: false,
            href: '/',
          },
          {
            text: 'Franquicias',
            disabled: false,
            href: '/',
          },
          {
            text: 'Franquicia',
            disabled: true,
            href: '',
          },
        ],
        horizontal2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        company: {},
        datas: [],
        dataAssigned: [],
        dataKeys: [],
        arrayOfData: [],
        selected: 1,
        typeDatas: [
          { id: 10, name: 'ACTIVIDADES' },
          { id: 14, name: 'CONTRATACIONES' },
          { id: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
          { id: 3, name: 'DATOS OPERATIVOS' },
          { id: 11, name: 'EXPERIENCIA' },
          { id: 4, name: 'INFORMACIÓN ECONÓMICA' },
          { id: 13, name: 'INFORMACION GENERAL' },
          { id: 9, name: 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO' },
          { id: 1, name: 'OBJETIVO' },
          { id: 5, name: 'OTROS DATOS IMPORTANTES' },
          { id: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
          { id: 6, name: 'RESEÑA' },
          { id: 12, name: 'SERVICIOS ESPECIALIZADOS' },
          { id: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
          { id: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
        ],
      }
    },
    components: {
      SidebarLayoutTwo,
      AdsSection3,
      ContactForm,
      AdsSection2,
      bannerVersionOne: Header2,
      GallerySlider,
      CompaniesCarousel2,
      Whatsapp,
      Slick,
    },
    watch: {
      country(newValue, oldValue) {
        this.horizontal1Ads.countryId = newValue.id
        this.horizontal2Ads.countryId = newValue.id
        this.vertical1Ads.countryId = newValue.id
        this.vertical2Ads.countryId = newValue.id
        this.getAdvertisements()
      },
    },
    computed: {
      galery() {
        if (this.company && this.company.uploads) {
          let imagesArray = []
          let images = this.company.uploads.filter((file) => file.type == 'image')
          images.forEach((image) => {
            imagesArray.push({ image: image.route })
          })
          return imagesArray
        } else {
          return [{ image: '' }]
        }
      },
      countryId() {
        if (localStorage.getItem('selectedCountry')) {
          return JSON.parse(localStorage.getItem('selectedCountry')).id
        } else {
          return ''
        }
      },
      slickOptions() {
        return {
          dots: true,
          infinite: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
          // variableWidth: true,
          // adaptiveHeight: true,

          // responsive: [
          //   {
          //     breakpoint: 1499,
          //     settings: {
          //       centerPadding: '0px',
          //       slidesToShow: 2,
          //     },
          //   },
          //   {
          //     breakpoint: 1025,
          //     settings: {
          //       centerPadding: '0px',
          //       slidesToShow: 1,
          //     },
          //   },
          //   {
          //     breakpoint: 991,
          //     settings: {
          //       centerPadding: '0px',
          //       slidesToShow: 1,
          //     },
          //   },
          //   {
          //     breakpoint: 575,
          //     settings: {
          //       centerPadding: '0px',
          //       slidesToShow: 1,
          //     },
          //   },
          // ],
        }
      },
      contactFormTitle() {
        if (this.company && this.company.type !== undefined) {
          switch (this.company.type) {
            case 2:
              return 'Solicita consultoría especializada'
            case 3:
              return 'Envía tu interés sobre estos servicios'
            default:
              return 'Adquiere una Franquicia'
          }
        } else {
          return '¡Contáctanos!'
        }
      },
    },
    created() {
      this.getCompany()
      this.getAdvertisements()
    },
    mounted() {
      this.adsTimer()
      if (this.$route.query.contact) {
        this.$modal.show('contactForm')
      }
    },
    methods: {
      async getCompany() {
        this.loading = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-by-slug/' +
              this.$route.params.slug
          )
          .then((response) => {
            this.company = response.data
            this.dataKeys = Object.keys(this.company.data_array)
            let dataEntries = Object.entries(this.company.data_array)
            let items = []
            this.typeDatas.forEach((key) => {
              items = dataEntries.filter((item) => item[0] == key.id)

              if (items.length > 0) {
                if (this.firstTag == '') {
                  //Seleccionar la primera pestaña a activar
                  this.firstTag = key.id
                }

                this.dataAssigned.push({
                  id: key.id,
                  title: key.name,
                  data: items.shift()[1],
                })
              }
            })

            this.selected = this.firstTag

            switch (this.company.type) {
              case 0:
                this.defaultLinks[1].text = 'Guía de Franquicias'
                this.defaultLinks[1].href = '/franquicias'
                break
              case 1:
                this.defaultLinks[1].text = 'Asociaciones de Franquicias'
                this.defaultLinks[1].href = '/asociaciones-franquicias'
                break
              case 2:
                this.defaultLinks[1].text = 'Consultores'
                this.defaultLinks[1].href = '/consultores'
                break
              case 3:
                this.defaultLinks[1].text = 'Proveedores'
                this.defaultLinks[1].href = '/proveedores'
                break
              case 4:
                this.defaultLinks[1].text = 'Oferta Inmobiliaria'
                this.defaultLinks[1].href = '/oferta-inmobiliaria'
                break

              default:
                this.defaultLinks[1].text = ''
                break
            }
            this.defaultLinks[2].text = this.company.name
            if (this.company.uploads) {
              this.company.images = this.company.uploads.filter(
                (file) => file.type == 'image'
              )
              this.company.cof = this.company.uploads
                .filter((file) => file.type == 'cof')
                .shift()
            } else {
              this.company.images = false
            }
            this.loading = false
            if (this.company) {
              this.message = 'Esta información no está disponible actualmente'
            }
            Object.assign(this.company, {
              infographics: this.company.uploads.filter(
                (file) => file.type == 'infographic'
              ),
            })
            this.slickIndex++
          })
          .catch((error) => {})
          .finally(() => {})
      },
      async getData() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'data?groupByType=1')
          .then((response) => {
            this.datas = response.data
            this.datas.map((x) => {
              let selectionableText
              switch (x.selection) {
                case 1:
                  selectionableText = 'Si'
                  break
                default:
                  selectionableText = 'No'
                  break
              }
              x.selectionableText = selectionableText
              let typeText
              switch (x.type) {
                case 2:
                  typeText = 'DATOS DE LA EMPRESA FRANQUICIANTE'
                  break
                case 3:
                  typeText = 'DATOS OPERATIVOS'
                  break
                case 4:
                  typeText = 'INFORMACIÓN ECONÓMICA'
                  break
                case 5:
                  typeText = 'OTROS DATOS IMPORTANTES'
                  break
                case 6:
                  typeText = 'RESEÑA'
                  break
                case 7:
                  typeText = 'UBICACIÓN Y DATOS DE CONTACTO'
                  break
                case 8:
                  typeText = 'TIPO DE FRANQUICIAS QUE OFRECEN'
                  break
                case 9:
                  typeText = 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO'
                  break
                case 10:
                  typeText = 'ACTIVIDADES'
                  break
                case 11:
                  typeText = 'EXPERIENCIA'
                  break
                case 12:
                  typeText = 'SERVICIOS ESPECIALIZADOS'
                  break
                case 13:
                  typeText = 'INFORMACION GENERAL'
                  break
                case 14:
                  typeText = 'CONTRATACIONES'
                  break
                default:
                  typeText = ''
                  break
              }
              x.typeText = typeText
              x.value = ''
              x.count = false
            })
            this.typeDatas.forEach((data) => {
              if (this.datas.filter((backData) => backData.type == data.id).length > 0) {
                this.arrayOfData.push({
                  id: data.id,
                  title: data.name,
                  data: this.datas.filter((backData) => backData.type == data.id),
                })
              }
            })
            this.selected = this.arrayOfData[0].id
            this.loading = false
          })
          .catch((error) => {})
          .finally(() => {})
      },
      adsTimer() {
        this.intervalFunction = setInterval(this.getAdvertisements, 60000)
      },
      async getAdvertisements() {
        const h1LimitStr = this.horizontal1Ads
          ? '&horizontal1[limit]=' + this.horizontal1Ads.limit
          : '&horizontal1[limit]=0'
        const h1CtryStr = this.horizontal1Ads
          ? '&horizontal1[country_id]=' + this.horizontal1Ads.countryId
          : '&horizontal1[country_id]=235'
        const h1OutStr = this.horizontal1Ads
          ? '&horizontal1[outstanding]=' + this.horizontal1Ads.outstanding
          : '&horizontal1[outstanding]=0'
        const h2LimitStr = this.horizontal2Ads
          ? '&horizontal2[limit]=' + this.horizontal2Ads.limit
          : '&horizontal2[limit]=0'
        const h2CtryStr = this.horizontal2Ads
          ? '&horizontal2[country_id]=' + this.horizontal2Ads.countryId
          : '&horizontal2[country_id]=235'
        const h2OutStr = this.horizontal2Ads
          ? '&horizontal2[outstanding]=' + this.horizontal2Ads.outstanding
          : '&horizontal2[outstanding]=0'
        const v1LimitStr = this.vertical1Ads
          ? '&vertical1[limit]=' + this.vertical1Ads.limit
          : '&vertical1[limit]=0'
        const v1CtryStr = this.vertical1Ads
          ? '&vertical1[country_id]=' + this.vertical1Ads.countryId
          : '&vertical1[country_id]=235'
        const v1OutStr = this.vertical1Ads
          ? '&vertical1[outstanding]=' + this.vertical1Ads.outstanding
          : '&vertical1[outstanding]=0'
        const v2LimitStr = this.vertical2Ads
          ? '&vertical2[limit]=' + this.vertical2Ads.limit
          : '&vertical2[limit]=0'
        const v2CtryStr = this.vertical2Ads
          ? '&vertical2[country_id]=' + this.vertical2Ads.countryId
          : '&vertical2[country_id]=235'
        const v2OutStr = this.vertical2Ads
          ? '&vertical2[outstanding]=' + this.vertical2Ads.outstanding
          : '&vertical2[outstanding]=0'

        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisement-management/get-advertisements?' +
              h1LimitStr +
              h1CtryStr +
              h1OutStr +
              h2LimitStr +
              h2CtryStr +
              h2OutStr +
              v1LimitStr +
              v1CtryStr +
              v1OutStr +
              v2LimitStr +
              v2CtryStr +
              v2OutStr
          )
          .then((response) => {
            this.h1Ads = response.data.horizontal1
            this.h2Ads = response.data.horizontal2
            this.v1Ads = response.data.vertical1
            this.v2Ads = response.data.vertical2
          })
          .catch((error) => {})
      },
      setDataText(dataId) {
        let data = []
        if (this.company.data) {
          data = this.company.data
            .filter((companyData) => companyData.id == dataId)
            .shift()
        }

        if (data && data != '') {
          return data.company_data.value
        } else {
          return ''
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
    metaInfo() {
      return {
        title: `${this.company.name} - XPOFranchise`,
        meta: [
          {
            name: 'description',
            content: this.company.description,
          },
          {
            property: 'og:title',
            content: `${this.company.name} - XPOFranchise`,
          },
          {
            property: 'og:description',
            content:
              'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
          },
          {
            property: 'og:image',
            content:
              'public/static/xpo_isotipo--white.svg',
          },
          {
            property: 'og:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            property: 'og:updated_time',
            content: this.company.updated_at,
          },
          {
            name: 'twitter:title',
            content: `${this.company.name} - XPOFranchise`,
          },
          {
            name: 'twitter:description',
            content:
              'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
          },
          {
            name: 'twitter:image',
            content:
              'public/static/xpo_isotipo--white.svg',
          },
          {
            name: 'twitter:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            name: 'og:updated_time',
            content: this.company.updated_at,
          },
        ],
      }
    },
  }
</script>

<style lang="scss">
  @import '@/assets/scss/custom-styles/vue2editor.scss';
  .active {
    background-color: #fff;
    color: #000;
  }
  .content {
    padding: 0;
  }
  .nav-pills .nav-item:not(:last-child) {
    padding-right: 0px;
  }
  .vm--modal {
    height: auto !important;
    max-height: 80% !important;
    top: 10% !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .bg-gradient-info {
    background: radial-gradient(
      circle,
      rgba(0, 155, 221, 1) 35%,
      rgba(0, 58, 86, 1) 100%
    );
  }
  .tab-content {
    padding: 0px;
  }
  .text-box p {
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
  }
  .infographic {
    background-position: center center;
    height: auto;
    padding: 5%;
    margin: 10px auto;
  }
  .slick-dots {
    position: initial;
    width: 100%;
  }
</style>
