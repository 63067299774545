<template>
  <div class="container" :key="`companies-carousel-${indexOne}`">
    <h5
      style="width: 100%; margin-top: 20px; display: inline-flex; justify-content: center"
    >
      {{ carouselTitle }}
    </h5>
    <div class="row" v-if="companies.length == 0">
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5" v-for="index in 5" :key="index">
        <v-skeleton-loader
          :boilerplate="boilerplateActive"
          class="mx-auto"
          type="image"
        ></v-skeleton-loader>
      </div>
    </div>
    <slick :options="slickOptions" v-else class="category-carouse1">
      <div
        class="item listing-item-container"
        v-for="company in companies"
        :key="company.id"
      >
        <div class="listing-item">
          <div class="listing-item-content">
            <a :href="'/empresa/' + company.link" class="image-container">
              <img
                v-if="!company.logo"
                src="/static/images/generico.png"
                :alt="company.name"
              />
              <img v-else :src="company.logo.route" :alt="company.name" />
            </a>
          </div>
        </div>
      </div>
      <h6 v-if="carouselEmpty">
        Ups! Parece que no hay otras empresas relacionadas... por ahora
      </h6>
    </slick>
  </div>
</template>
<script>
  import Slick from 'vue-slick'
  export default {
    props: ['country', 'company'],
    components: {
      Slick,
    },
    data() {
      return {
        indexOne: 0,
        intervalFunction: '',
        companies: [],
        loading: true,
        boilerplateActive: false,
        carouselTitle: '',
        carouselEmpty: '',
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.getCompanies()
      },
      type(newValue, oldValue) {
        this.getCompanies()
        this.adsTimer()
      },
    },
    created() {
      this.getCompanies()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getCompanies, 60000)
      },
      async getCompanies() {
        let categoryStr = ''
        let countryStr = ''
        let limitStr = '&limit=30'
        // let outstangingStr = ''
        if (this.country && this.country.id && this.company.type !== 1) {
          countryStr = '&country_id=' + this.country.id
        }
        if (this.company.categories && this.company.categories.length > 0) {
          categoryStr = '&category_id=' + this.company.categories[0].id
        }

        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-list?random=1&active=1&type=' +
              this.company.type +
              countryStr +
              limitStr +
              categoryStr
          )

          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
              this.carouselEmpty = true
            }
            this.loading = false
            this.companies = this.companies.filter((item) => item.id != this.company.id)
            switch (this.company.type) {
              case 0:
                this.carouselTitle = 'Franquicias Relacionadas'
                break
              case 1:
                this.carouselTitle = 'Asociaciones de Franquicia Relacionadas'
                break
              case 2:
                this.carouselTitle = 'Consultores Relacionados'
                break
              case 3:
                this.carouselTitle = 'Proveedores Relacionados'
                break
              case 4:
                this.carouselTitle = 'Oferta Inmobiliaria Relacionada'
              default:
                this.carouselTitle = 'Franquicias Relacionadas'
                break
            }
            this.indexOne++
          })
      },
    },
    computed: {
      slickOptions() {
        return {
          centerMode: true,
          centerPadding: '0%',
          slidesToShow: 8,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 1000,
          infinite: true,
          variableWidth: false,
          responsive: [
            {
              breakpoint: 1441,
              settings: {
                centerPadding: '0px',
                slidesToShow: 8,
              },
            },
            {
              breakpoint: 1281,
              settings: {
                centerPadding: '0px',
                slidesToShow: 6,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                centerPadding: '0px',
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
          ],
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>
<style lang="css" scoped>
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: contents;
    height: inherit;
  }
  /* .slick-slider .slick-track,
      .slick-slider .slick-list {
        height: inherit;
      } */
  /* .fullwidth-slick-carousel.category-carousel .slick-slide {
        height: 230px;
      } */
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .fullwidth-slick-carousel .slick-slide {
    pointer-events: all !important;
  }
  .category-carouse1 {
    height: inherit;
    pointer-events: all !important;
  }

  .fullwidth-slick-carousel.category-carousel .slick-slide,
  .listing-item-container,
  .listing-item {
    /* margin: 24px 0 !important; */
    height: 200px;
  }
  .container {
    margin: 0;
    padding: 0;
  }
  .listing-item-container {
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .listing-item {
    height: inherit;
    box-shadow: none;
    /* box-shadow: 0px 4px 10px 2px rgb(0 0 0 / 20%);
      border-radius: 5px; */
  }
  .listing-item-content {
    height: inherit;
    padding: 0px;
  }
  .image-container {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container img {
    /* margin: 0 !important; */
    max-width: 60%;
    max-height: 100%;
    /* max-height: 80%; */
  }
</style>
